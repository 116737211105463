import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    seo: {
      title: "AngelEye",
      description:
        "AngelEye è un sistema intelligente per prevenire gli annegamenti nelle piscine. Mabiloft ha contribuito a rendere l'app di facile utilizzo.",
    },
    head: {
      title_1: "Perfezionare l'interazione tra dispositivi",
      title_2: "di un'app salvavita",
      description:
        "AngelEye è un sistema intelligente capace di rilevare gli annegamenti nelle piscine grazie alla potenza dell’IA e a telecamere subacquee di ultima generazione. Il team di AngelEye si è rivolto a Mabiloft per migliorare il design della propria app, aggiungere nuove funzionalità e migliorare l’integrazione tra i vari dispositivi coinvolti dal servizio.",
    },
    widgets: {
      title_1: "Widget o non widget?",
      title_2: "Dilemma risolto",
      text_1:
        "Abbiamo migliorato l'app per renderla più facile da usare e personalizzabile.",
      text_2:
        "In questo modo, l'app è più efficiente e funzionale per tutti gli utenti.",
      text_3:
        "Ogni bagnino può ora organizzare la propria schermata principale con widget intelligenti e intuitivi.",
      text_4:
        "Un sistema di login intuitivo permette a ciascun operatore di primo soccorso di accedere alla propria versione dell'app e di personalizzare la propria interfaccia.",
      widgetsStrings: {
        widget: "Widget",
        cameraDetection: "Rilevamento camera",
        lights: "Luci",
        poolName: "Nome vasca",
        cameras: "Camere",
        active_fm: "attive",
        capacity: "Capienza raggiunta",
        safeguard: "Dati safeguard",
        totalCapacity: "Capienza complessiva attuale",
        cameraRecord: "Registrazione video",
        active: "Attiva",
        device: "Device collegati",
        tablet: "Palmare",
        watch: "Watch",
        accessControl: "Access control",
        inactive: "Inattivo per orario",
        densityControl: "Density control",
        malfunctioning: "Malfunzionamento",
        videoRecording: "Video recording",
      },
    },
    monitoring: {
      title: "Monitoraggio efficiente ed omni-canale",
      description:
        "Abbiamo sviluppato un'app mobile e la sua controparte tablet, entrambe personalizzabili per ogni utente. La nuova dashboard del bagnino offre un monitoraggio semplificato, consentendo di visualizzare le piscine e le telecamere con facilità. I widget aggiuntivi per lanciare allarmi manuali e controllare l'affluenza garantiscono una gestione efficiente e la raccolta di dati e feedback per migliorare costantemente l'IA di AngelEye.",
    },
    watch: {
      title:
        "AngelEye al polso dei bagnini: app per WearOS e WatchOS per interventi immediati.",
      subtitle_1: "Sempre attivi, ovunque.",
      text_1:
        "L’app riduce i tempi di risposta e garantisce la massima prontezza in caso di emergenza.",
      subtitle_2: "Massima sicurezza",
      text_2:
        "La pluralità di dispositivi connessi aumenta le possibilità di intervento tempestivo.",
    },
    drawning: {
      title: "Un sistema phygital perfettamente integrato",
      description:
        "Il nucleo del progetto è l'integrazione avanzata tra hardware, come le videocamere di allarme, e diverse applicazioni digitali. Ciascuna piscina è connessa a un server centrale per assicurare una comunicazione immediata. Il sistema AngelEye è in grado di rilevare incidenti di annegamento sia localmente che da remoto, rispettando gli standard di sicurezza in termini di tempi di risposta.",
      label: "Annegamento rilevato",
    },
  },
  en: {
    seo: {
      title: "AngelEye",
      description:
        "AngelEye is an intelligent system to prevent drowning in swimming pools. Mabiloft has helped make the app easy to use.",
    },
    head: {
      title_1: "Perfecting Interaction Between Devices",
      title_2: "of a Life-Saving App",
      description:
        "AngelEye is an intelligent system capable of detecting drownings in swimming pools thanks to the power of AI and next-generation underwater cameras. The AngelEye team approached Mabiloft to improve the design of their app, add new features, and enhance the integration between the various devices involved in the service.",
    },
    widgets: {
      title_1: "Widget or no Widget?",
      title_2: "Dilemma Solved",
      text_1: "We improved the app to make it easier to use and customizable.",
      text_2:
        "This way, the app is more efficient and functional for all users.",
      text_3:
        "Each lifeguard can now organize their main screen with smart and intuitive widgets.",
      text_4:
        "An intuitive login system allows each first responder to access their version of the app and customize their interface.",
      widgetsStrings: {
        widget: "Widget",
        cameraDetection: "Camera Detection",
        lights: "Lights",
        poolName: "Pool Name",
        cameras: "Cameras",
        active_fm: "active",
        capacity: "Capacity Reached",
        safeguard: "Safeguard Data",
        totalCapacity: "Current Total Capacity",
        cameraRecord: "Video Recording",
        active: "Active",
        device: "Connected Devices",
        tablet: "Tablet",
        watch: "Watch",
        accessControl: "Access Control",
        inactive: "Inactive",
        densityControl: "Density Control",
        malfunctioning: "Malfunction",
        videoRecording: "Video Recording",
      },
    },
    monitoring: {
      title: "Efficient and Omni-Channel Monitoring",
      description:
        "We developed a mobile app and its tablet counterpart, both customizable for each user. The new lifeguard dashboard offers simplified monitoring, allowing pools and cameras to be easily viewed. Additional widgets for launching manual alarms and controlling attendance ensure efficient management and the collection of data and feedback to continuously improve AngelEye's AI.",
    },
    watch: {
      title:
        "AngelEye on Lifeguards' Wrists: Apps for WearOS and WatchOS for Immediate Response",
      subtitle_1: "Always Active, Anywhere.",
      text_1:
        "The app reduces response times and ensures maximum readiness in emergencies.",
      subtitle_2: "Maximum Safety",
      text_2:
        "The variety of connected devices increases the chances of timely intervention.",
    },
    drawning: {
      title: "A Perfectly Integrated Phygital System",
      description:
        "The core of the project is the advanced integration between hardware, such as alarm cameras, and various digital applications. Each pool is connected to a central server to ensure immediate communication. The AngelEye system can detect drowning incidents both locally and remotely, meeting safety standards in terms of response times.",
      label: "Drowning Detected",
    },
  },
};

export const l_angeleye = (
  trad: DeepKeyof<(typeof traduction)[keyof typeof traduction]>,
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
