import { AngeleyeIconProps } from "./AngeleyeIconProps";

const ArrowLeftIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_35_8033"
        width="10"
        height="16"
        x="7"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          d="M16.254 4.241c.298.292.325.75.081 1.072l-.08.092L9.526 12l6.727 6.595c.298.292.325.75.081 1.072l-.08.092a.85.85 0 0 1-1.094.08l-.094-.08-7.321-7.177a.81.81 0 0 1-.081-1.072l.08-.092 7.322-7.177a.85.85 0 0 1 1.187 0"
        ></path>
      </mask>
      <g mask="url(#mask0_35_8033)">
        <path fill={color} d="M0-.061h24v24H0z"></path>
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
