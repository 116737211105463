import ArrowLeftIcon from "../icons/ArrowLeft";
import ArrowRightIcon from "../icons/ArrowRight";
import ChangeIcon from "../icons/ChangeIcon";
import WarningIcon from "../icons/WarningIcon";

// @ts-ignore
import poolPicture from "../../assets/images/pool.jpg";
import { l_angeleye } from "../../locales";

const SlideShowCard: React.FC = () => {
  return (
    <div className="flex h-[256px] w-[320px] flex-col items-stretch text-white">
      <div
        className="w-full flex-1 rounded-t-xl"
        style={{
          backgroundImage: `url(${poolPicture})`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-row items-center justify-between gap-3 px-4 py-3">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FF5349] opacity-30">
            <WarningIcon color="#ffffff" />
          </div>
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#004587]/85">
            <ChangeIcon color="#ffffff" />
          </div>
        </div>
      </div>
      <div className="box-border flex h-10 w-full flex-row items-center justify-between rounded-b-xl bg-[#1F5B95] px-4">
        <ArrowLeftIcon color="#ffffff" />
        <span className="text-[14px] font-bold">
          {l_angeleye("widgets.widgetsStrings.poolName")} - CamAE02
        </span>
        <ArrowRightIcon color="#ffffff" />
      </div>
    </div>
  );
};

export default SlideShowCard;
