type MiniCardProps = {
  title: string;
  icon: React.ReactNode;
  label: string;
  expanded?: boolean;
};

const MiniCard: React.FC<MiniCardProps> = (props) => {
  const { title, icon, label, expanded = false } = props;

  return (
    <div
      className="flex flex-col items-stretch gap-2 rounded-2xl bg-white p-3 text-[#131F44] shadow-[0px_2px_14px_rgba(0,0,0,0.06)]"
      style={{
        width: expanded ? 160 : 104,
      }}
    >
      <div className="flex flex-row items-center gap-2">
        {!expanded ? icon : null}
        <span className="text-[14px] font-bold">{title}</span>
      </div>
      <div className="flex flex-row items-center gap-2">
        {expanded ? icon : null}
        <span className="text-nowrap text-[12px]">{label}</span>
      </div>
    </div>
  );
};

export default MiniCard;
