import { l_angeleye } from "../../locales";
import PeopleIcon from "../icons/PeopleIcon";

const ProgressBarCard: React.FC = () => {
  return (
    <div className="flex w-[320px] flex-col items-stretch gap-2 rounded-2xl bg-white p-4 text-[#131F44] shadow-[0px_2px_14px_rgba(0,0,0,0.06)]">
      <div className="flex flex-row items-center gap-4">
        <PeopleIcon />
        <span className="text-[12px]">
          <span className="text-[18px] font-bold">27</span>/132
        </span>
      </div>
      <p className="m-0 text-[14px]">
        {l_angeleye("widgets.widgetsStrings.capacity")}
      </p>
      <div className="flex flex-row items-center gap-4">
        <div className="h-3 w-full flex-1 rounded-full bg-[rgba(0,0,0,0.06)]">
          <div className="h-3 w-[31%] rounded-full bg-[#5AC8FA]"></div>
        </div>
        <span className="text-[12px]">31%</span>
      </div>
    </div>
  );
};

export default ProgressBarCard;
