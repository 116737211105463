import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import DarkBackground from "./components/DarkBackground";
import DrawningAgainSection from "./components/DrawningAgainSection";
import DrawningSection from "./components/DrawningSection";
import MonitoringSection from "./components/MonitoringSection";
import WatchSection from "./components/WatchSection";
import WidgetsSection from "./components/WidgetsSection";

const Angeleye = () => {
  return (
    <>
      <DarkBackground />
      <WidgetsSection />
      <MonitoringSection />
      <WatchSection />
      <DrawningSection />
      <DrawningAgainSection />
      <PrevNextCaseStudy />
      <Footer />
    </>
  );
};

export default Angeleye;
