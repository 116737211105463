import { animated, useSpring } from "react-spring";
import { l_angeleye } from "../locales";
import CameraFilledIcon from "./icons/CameraFilledIcon";
import NotificationIcon from "./icons/NotificationIcon";

const DrawningAgainSection: React.FC = () => {
  const [container] = useSpring(() => {
    return {
      from: {
        background:
          "linear-gradient(170deg,hsl(217deg 43% 10%) 0%,hsl(212deg 59% 42%) 29%,hsl(204deg 64% 49%) 43%,hsl(192deg 60% 50%) 57%,hsl(185deg 58% 52%) 71%,hsl(194deg 61% 48%) 100%)",
      },
      to: [
        {
          background:
            "linear-gradient(210deg,hsl(217deg 43% 10%) 0%,hsl(212deg 59% 42%) 29%,hsl(204deg 64% 49%) 43%,hsl(192deg 60% 50%) 57%,hsl(185deg 58% 52%) 71%,hsl(194deg 61% 48%) 100%)",
        },
        {
          background:
            "linear-gradient(275deg,hsl(217deg 43% 10%) 0%,hsl(212deg 59% 42%) 29%,hsl(204deg 64% 49%) 43%,hsl(192deg 60% 50%) 57%,hsl(185deg 58% 52%) 71%,hsl(194deg 61% 48%) 100%)",
        },
      ],
      // delay: 1000,
      loop: true,
      reset: true,
      config: { duration: 27500 },
    };
  }, []);
  return (
    <animated.div
      className="flex flex-col gap-8 bg-[#0E1623] py-16 md:gap-20 md:py-40"
      style={container}
    >
      <div className="mx-auto box-border flex w-full max-w-screen-lg flex-col items-center justify-between gap-20 px-4">
        <div className="flex h-28 w-28 flex-row items-center justify-center rounded-full bg-[#C1CFE6]">
          <NotificationIcon color="#ffffff" size={48} />
        </div>
        <div className="flex flex-1 flex-row justify-center">
          <div className="flex flex-row items-center gap-4 rounded-full border-2 border-solid border-white bg-[#C1CFE6] p-4">
            <span className="text-[18px] font-bold text-[#0E1623]">
              {l_angeleye("drawning.label")}
            </span>
            <CameraFilledIcon />
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default DrawningAgainSection;
