import { AngeleyeIconProps } from "./AngeleyeIconProps";

const DoneIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#4CCE6D" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect width="18" height="18" x="3" y="3" fill={color} rx="9"></rect>
      <mask
        id="mask0_4660_2794"
        width="10"
        height="8"
        x="7"
        y="8"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.715 8.712a.564.564 0 0 1 .804 0l.222-.22-.222.22a.585.585 0 0 1 0 .82l-5.662 5.718a.564.564 0 0 1-.803 0L7.48 12.651a.585.585 0 0 1 0-.82.564.564 0 0 1 .804 0l1.95 1.969a.31.31 0 0 0 .444 0zl-.22-.218z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_4660_2794)">
        <path fill="#fff" d="M4.5 4.481h15v15h-15z"></path>
      </g>
    </svg>
  );
};

export default DoneIcon;
