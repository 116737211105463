import { AngeleyeIconProps } from "./AngeleyeIconProps";

const NotificationIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4660_2832"
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill="#D9D9D9" d="M0 0h24v24H0z"></path>
      </mask>
      <g fill={color} mask="url(#mask0_4660_2832)">
        <path d="M15.034 7.9a2.66 2.66 0 0 0 1.948.802A2.65 2.65 0 0 0 18.93 7.9a2.66 2.66 0 0 0 .802-1.948q0-1.146-.803-1.947a2.65 2.65 0 0 0-1.948-.802q-1.146 0-1.947.803a2.66 2.66 0 0 0-.802 1.948q0 1.146.803 1.947"></path>
        <path d="M11.499 21.192q-.747 0-1.277-.53a1.74 1.74 0 0 1-.53-1.277h3.616q0 .747-.532 1.277-.531.53-1.277.53M4.75 18.385a.73.73 0 0 1-.534-.216.73.73 0 0 1-.216-.535q0-.318.216-.534a.73.73 0 0 1 .534-.215h1.058V9.423q0-2.017 1.245-3.567a5.52 5.52 0 0 1 3.197-1.983V3.25q0-.52.364-.885T11.5 2t.886.365q.365.365.365.885v.2a5 5 0 0 0-.39.904 4 4 0 0 0-.177.954 9 9 0 0 0-.333-.054 2.5 2.5 0 0 0-.35-.023q-1.737 0-2.964 1.228-1.228 1.227-1.228 2.964v7.462h8.385v-6.252q.343.123.726.192.384.069.773.06v6h1.058q.319 0 .535.215a.73.73 0 0 1 .215.535q0 .318-.215.534a.73.73 0 0 1-.535.216z"></path>
      </g>
    </svg>
  );
};

export default NotificationIcon;
