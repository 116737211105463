/** @jsxImportSource @emotion/react */

import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

type AngeleyeTypographyProps = {
  variant?: "title" | "body1" | "label" | "caption" | "randomAssText";
  color?:
    | "black"
    | "white"
    | "joule_dark"
    | "joule_light"
    | "joule_medium"
    | "joule_intersection";
  className?: string;
} & Omit<TypographyProps, "variant" | "color">;

const AngeleyeTypography: React.FC<AngeleyeTypographyProps> = forwardRef(
  (props, ref) => {
    const { variant, color, sx, ...otherProps } = props;

    return (
      <Typography
        ref={ref}
        component={variant === "label" ? "span" : "p"}
        sx={{
          ...(color === "black"
            ? { color: "#000000" }
            : color === "white"
              ? { color: "#FFF" }
              : color === "joule_dark"
                ? { color: "#1F8FFF" }
                : color === "joule_light"
                  ? { color: "#DAEAFF" }
                  : color === "joule_medium"
                    ? { color: "#93C9FA" }
                    : color === "joule_intersection"
                      ? { color: "#A6D6FA" }
                      : { color: "#000000" }),
          ...(variant === "title"
            ? {
                fontSize: { xs: "14px", sm: "14px", md: "26px" },
                fontWeight: 700,
                zIndex: 2,
              }
            : variant === "body1"
              ? {
                  fontSize: { xs: "14", md: "16px" },
                  zIndex: 2,
                }
              : variant === "label"
                ? {
                    fontSize: "11px",
                    zIndex: 2,
                  }
                : variant === "caption"
                  ? {
                      fontSize: {
                        xs: "24px",
                        md: "20px",
                      },
                      fontWeight: 700,
                      zIndex: 2,
                    }
                  : variant === "randomAssText"
                    ? {
                        fontSize: { xs: "14px", md: "25px" },
                        fontWeight: { xs: "400", md: "700" },
                        zIndex: 2,
                      }
                    : {}),

          ...sx,
        }}
        {...otherProps}
      />
    );
  },
);

export default AngeleyeTypography;
