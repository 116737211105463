import { AngeleyeIconProps } from "./AngeleyeIconProps";

const LightIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_35_7846"
        width="12"
        height="17"
        x="6"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          d="M12.028 20.006a4.7 4.7 0 0 1-2.82-.853.513.513 0 0 1 .623-.813 3.68 3.68 0 0 0 2.197.643 3.68 3.68 0 0 0 2.196-.643.512.512 0 1 1 .623.814 4.7 4.7 0 0 1-2.707.852zm0-1.768a4.7 4.7 0 0 1-2.82-.856.512.512 0 1 1 .623-.814c.645.443 1.415.67 2.197.646a3.68 3.68 0 0 0 2.196-.646.512.512 0 1 1 .623.814 4.7 4.7 0 0 1-2.714.856zm-3.2-3.188a5.8 5.8 0 0 0-.738-1.634c-.634-1.143-1.423-2.562-1.423-4.051a5.365 5.365 0 1 1 10.73 0c0 1.486-.79 2.909-1.422 4.049a5.7 5.7 0 0 0-.738 1.634.513.513 0 0 1-.513.472h-.041a.513.513 0 0 1-.467-.551 5.8 5.8 0 0 1 .861-2.05c.608-1.092 1.294-2.327 1.294-3.552a4.336 4.336 0 0 0-8.672 0c0 1.225.687 2.46 1.294 3.552.417.621.709 1.318.861 2.05a.514.514 0 0 1-.471.553H9.34a.515.515 0 0 1-.514-.473z"
        ></path>
      </mask>
      <g mask="url(#mask0_35_7846)">
        <path fill={color} d="M0 0h24v24H0z"></path>
      </g>
    </svg>
  );
};

export default LightIcon;
