import { l_angeleye } from "../../locales";
import ArrowRightIcon from "../icons/ArrowRight";
import TabletIcon from "../icons/TabletIcon";
import WatchIcon from "../icons/WatchIcon";

const CarouselCard: React.FC = () => {
  return (
    <div className="flex w-[320px] flex-col items-stretch gap-2 rounded-2xl bg-white text-[#131F44] shadow-[0px_2px_14px_rgba(0,0,0,0.06)]">
      <div className="flex flex-row items-center justify-between gap-4 px-4 py-3">
        <span className="text-[14px] font-bold">
          {l_angeleye("widgets.widgetsStrings.device")}
        </span>
        <ArrowRightIcon />
      </div>
      <div className="hide-scrollbar flex flex-row flex-nowrap gap-2 overflow-x-auto p-4 pt-1">
        {items?.map((item, index) => (
          <div
            className="flex flex-col items-center gap-2 rounded-2xl bg-[#F5F7FA]/60 px-4 py-2 shadow-sm"
            key={index}
          >
            {item?.type === "tablet" ? <TabletIcon /> : <WatchIcon />}
            <span className="text-nowrap text-[12px]">{item?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

type CarouselItem = {
  type: "tablet" | "watch";
  name: string;
};

const items: CarouselItem[] = [
  {
    type: "tablet",
    name: `${l_angeleye("widgets.widgetsStrings.tablet")} 1`,
  },
  {
    type: "tablet",
    name: `${l_angeleye("widgets.widgetsStrings.tablet")} 2`,
  },
  {
    type: "watch",
    name: `${l_angeleye("widgets.widgetsStrings.watch")} 1`,
  },
  {
    type: "tablet",
    name: `${l_angeleye("widgets.widgetsStrings.tablet")} 3`,
  },
  {
    type: "watch",
    name: `${l_angeleye("widgets.widgetsStrings.watch")} 2`,
  },
  {
    type: "watch",
    name: `${l_angeleye("widgets.widgetsStrings.watch")} 3`,
  },
];

export default CarouselCard;
