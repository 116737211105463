import { useEffect, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import { l_angeleye } from "../locales";
import DoneIcon from "./icons/DoneIcon";
import FallIcon from "./icons/FallIcon";
import LightIcon from "./icons/LightIcon";
import PeopleErrorIcon from "./icons/PeopleErrorIcon";
import ShieldIcon from "./icons/ShieldIcon";
import WidgetIcon from "./icons/WidgetIcon";
import CarouselCard from "./widgets/CarouselCard";
import ChartCard from "./widgets/ChartCard";
import MiniCard from "./widgets/MiniCard";
import ProgressBarCard from "./widgets/ProgressBarCard";
import SlideShowCard from "./widgets/SlideShowCard";
import SmallSwitchCard from "./widgets/SmallSwitchCard";
import YellowCard from "./widgets/YellowCard";

type WidgetsSectionProps = {
  children?: React.ReactNode;
};

const WidgetsSection: React.FC<WidgetsSectionProps> = (props) => {
  const [isActive, setIsActive] = useState(false);

  const [ref, isInView] = useInView({
    amount: "any",
    rootMargin: "300px 0px",
  });

  const widgetSwitchCard = useSpring({
    y: isActive ? -220 : 0,
  });

  const slideshowCard = useSpring({
    x: isActive ? 0 : 120,
    y: isActive ? 0 : 320,
    opacity: isActive ? 1 : 0,
  });

  const yellowCard = useSpring({
    x: isActive ? 282 : 242,
    y: -65,
    opacity: isActive ? 1 : 0,
  });

  const cameraSwitchCard = useSpring({
    x: isActive ? 484 : 524,
    y: -77,
    opacity: isActive ? 1 : 0,
  });

  const progressBarCard = useSpring({
    x: isActive ? 370 : 470,
    y: isActive ? 72 : 135,
    opacity: isActive ? 1 : 0,
  });

  const lightSwitchCard = useSpring({
    x: isActive ? 166 : 224,
    y: isActive ? 218 : 288,
    opacity: isActive ? 1 : 0,
  });

  const chartCard = useSpring({
    x: isActive ? -254 : -324,
    y: isActive ? 260 : 264,
    opacity: isActive ? 1 : 0,
  });

  const recordVideoCard = useSpring({
    x: isActive ? -406 : -324,
    y: 86,
    opacity: isActive ? 1 : 0,
  });

  const carouselCard = useSpring({
    x: isActive ? -436 : -368,
    y: isActive ? -88 : 0,
    opacity: isActive ? 1 : 0,
  });

  const miniCards = useSpring({
    x: isActive ? -512 : -628,
    y: -272,
    opacity: isActive ? 1 : 0,
  });

  const title = useSpring({
    opacity: isActive ? 1 : 0,
    y: isActive ? 0 : 48,
  });

  useEffect(() => {
    if (isInView) {
      setIsActive(true);
    }
  }, [isInView]);

  return (
    <div className="flex h-auto w-full flex-col items-center justify-center">
      <div className="mb-4 mt-16 md:mb-8 md:mt-24">
        <h1 className="mt-2 text-center text-2xl font-bold md:text-4xl">
          {l_angeleye("widgets.title_1")}
        </h1>
        <animated.h1
          className="mt-2 text-center text-2xl font-bold md:text-4xl"
          style={title}
        >
          {l_angeleye("widgets.title_2")}
        </animated.h1>
      </div>
      <div className="relative h-[720px] w-full overflow-hidden max-sm:-mb-16 max-sm:-mt-24 max-sm:w-[200%] max-sm:scale-75">
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={widgetSwitchCard}
          ref={ref}
        >
          <SmallSwitchCard
            title={l_angeleye("widgets.widgetsStrings.widget")}
            icon={<WidgetIcon />}
            active={isActive}
            onToggle={() => {
              setIsActive(!isActive);
            }}
          />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={slideshowCard}
        >
          <SlideShowCard />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={yellowCard}
        >
          <YellowCard />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={cameraSwitchCard}
        >
          <SmallSwitchCard
            title={l_angeleye("widgets.widgetsStrings.cameraDetection")}
            icon={<ShieldIcon />}
          />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={progressBarCard}
        >
          <ProgressBarCard />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={lightSwitchCard}
        >
          <SmallSwitchCard
            title={l_angeleye("widgets.widgetsStrings.lights")}
            icon={<LightIcon />}
            children={
              <span className="text-[14px]">
                <span className="font-bold">3</span>/4
              </span>
            }
          />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={chartCard}
        >
          <ChartCard />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={recordVideoCard}
        >
          <MiniCard
            title={l_angeleye("widgets.widgetsStrings.cameraRecord")}
            icon={<DoneIcon size={20} />}
            label={l_angeleye("widgets.widgetsStrings.active")}
            expanded
          />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={carouselCard}
        >
          <CarouselCard />
        </animated.span>
        <animated.span
          className="absolute left-[50%] top-[50%] z-10 w-0 [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%]"
          style={miniCards}
        >
          <span className="flex flex-row items-center gap-2">
            <MiniCard
              title={l_angeleye("widgets.widgetsStrings.accessControl")}
              icon={<FallIcon />}
              label={l_angeleye("widgets.widgetsStrings.inactive")}
            />
            <MiniCard
              title={l_angeleye("widgets.widgetsStrings.densityControl")}
              icon={<PeopleErrorIcon />}
              label={l_angeleye("widgets.widgetsStrings.malfunctioning")}
            />
            <MiniCard
              title={l_angeleye("widgets.widgetsStrings.videoRecording")}
              icon={<DoneIcon />}
              label={l_angeleye("widgets.widgetsStrings.active")}
            />
          </span>
        </animated.span>
      </div>
      <div className="py-16 md:py-24">
        <div className="mx-auto box-border flex w-full max-w-screen-md flex-col items-stretch gap-4 px-4 sm:flex-row sm:items-start md:px-8">
          <div className="flex-1">
            <p>
              <b>{l_angeleye("widgets.text_1")}</b>
              <br />
              {l_angeleye("widgets.text_2")}
            </p>
          </div>
          <div className="flex-1">
            <p>
              {l_angeleye("widgets.text_3")}
              <br />
              <br />
              {l_angeleye("widgets.text_4")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetsSection;
