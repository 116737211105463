import { l_angeleye } from "../locales";
import CameraFilledIcon from "./icons/CameraFilledIcon";

type DrawningSectionProps = {
  children?: React.ReactNode;
};

const DrawningSection: React.FC<DrawningSectionProps> = (props) => {
  return (
    <div className="flex flex-col bg-[#0E1623] py-16 md:py-40">
      <div className="mx-auto box-border flex w-full max-w-screen-lg flex-col items-stretch justify-between gap-12 px-4 md:flex-row md:items-center md:gap-16 md:px-8">
        <div className="flex flex-col gap-8 md:max-w-[345px]">
          <p className="m-0 text-[26px] font-bold text-[#C1CFE6]">
            {l_angeleye("drawning.title")}
          </p>
          <p className="m-0 text-[#C1CFE6]">
            {l_angeleye("drawning.description")}
          </p>
        </div>
        <div className="flex flex-1 flex-row justify-center">
          <div className="flex flex-row items-center gap-4 rounded-full border-2 border-solid border-white bg-[#C1CFE6] p-4">
            <span className="text-[18px] font-bold text-[#0E1623]">
              {l_angeleye("drawning.label")}
            </span>
            <CameraFilledIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawningSection;
