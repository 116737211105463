import { StaticImage } from "gatsby-plugin-image";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { animated, useInView, useSpring } from "react-spring";
import { useDimensions } from "../../../../components/old/withDimensions";
// @ts-expect-error cannot find the file
import BackgroundVideo from "../assets/videos/background-video.mp4";
import { darkModeAppbarAtom } from "../atoms/darkModeAppbarAtom";
import { l_angeleye } from "../locales";
import AngeleyeTypography from "./theme/AngeleyeTypography";

type DarkBackgroundProps = {
  children?: React.ReactNode;
};

const DarkBackground: React.FC<DarkBackgroundProps> = (props) => {
  const setDarkModeAppbar = useSetAtom(darkModeAppbarAtom);

  const [dimensions] = useDimensions();
  const { width, height } = dimensions;

  const [ref, isInView] = useInView({
    amount: "any",
    rootMargin: "200px 0px",
  });

  const [containerRef, isSectionVisible] = useInView({
    amount: "any",
  });

  const videoStyles = useSpring({
    clipPath:
      isInView || width < 900
        ? `circle(${2 * Math.max(width, height)}px at center)`
        : "circle(44px at center)",
    config: { tension: 280, friction: isInView || width < 900 ? 180 : 40 },
  });

  const logoStyles = useSpring({
    opacity: isInView || width < 900 ? 0 : 1,
    scale: isInView || width < 900 ? 0 : 1,
  });

  useEffect(() => {
    if (isSectionVisible) {
      setDarkModeAppbar({
        darkMode: true,
        backgroundColor: "#0E1623",
      });
    } else {
      setDarkModeAppbar({ darkMode: false });
    }

    return () => {
      setDarkModeAppbar({ darkMode: false });
    };
  }, [isSectionVisible]);

  return (
    <div
      className="mt-0 h-auto w-full bg-[#0E1623] text-white"
      style={{ overflow: "initial" }}
    >
      <div
        className="left-0 top-0 z-[1] h-[80vh] w-full bg-[#0E1623] md:sticky md:h-[100vh]"
        ref={containerRef}
      >
        <animated.video
          autoPlay
          loop
          muted
          className="h-full w-full object-cover"
          style={videoStyles}
          webkit-playsinline
          playsInline
        >
          <source src={BackgroundVideo} type="video/mp4" />
        </animated.video>
        <animated.div
          className="absolute left-0 top-0 m-0 flex h-full w-full flex-col items-center justify-center"
          style={logoStyles}
        >
          <StaticImage
            src="../assets/images/angeleye_logo.svg"
            alt="Angeleye Logo"
            width={426}
            height={108}
          />
          <div className="h-4" /> {/* Spacer */}
        </animated.div>
        <div className="hidden max-md:absolute max-md:left-0 max-md:top-0 max-md:flex max-md:h-[80vh] max-md:w-full max-md:flex-col max-md:items-center max-md:justify-center">
          <StaticImage
            src="../assets/images/angeleye_logo_small.svg"
            alt="Angeleye Logo"
            quality={100}
          />
        </div>
      </div>
      <div className="relative z-[2] m-0">
        <AngeleyeTypography
          variant="caption"
          color="white"
          className="absolute -translate-y-24 px-6 opacity-80 max-md:hidden"
        >
          {l_angeleye("head.title_1")}
          <br />
          {l_angeleye("head.title_2")}
        </AngeleyeTypography>
        <div className="m-0 box-border flex w-full flex-col items-stretch justify-between gap-10 p-6 py-14 md:h-screen md:flex-row md:items-center md:bg-transparent">
          <div className="self-end max-md:hidden">
            <StaticImage
              src="../assets/images/angeleye_logo_small.svg"
              alt="Angeleye Logo"
            />
          </div>
          <AngeleyeTypography
            variant="caption"
            className="hidden text-white max-md:block"
          >
            {l_angeleye("head.title_1")}
            <br />
            {l_angeleye("head.title_2")}
          </AngeleyeTypography>
          <AngeleyeTypography
            variant="randomAssText"
            className="text-white md:max-w-[600px]"
            ref={ref}
          >
            {l_angeleye("head.description")}
          </AngeleyeTypography>
        </div>
      </div>
    </div>
  );
};

export default DarkBackground;
