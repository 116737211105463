import { AngeleyeIconProps } from "./AngeleyeIconProps";

const CameraIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_35_7857"
        width="21"
        height="16"
        x="2"
        y="5"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <mask
          id="mask1_35_7857"
          width="16"
          height="16"
          x="2"
          y="5"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "luminance" }}
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M2 5h15.5v15.501H2z"
            clipRule="evenodd"
          ></path>
        </mask>
        <g mask="url(#mask1_35_7857)">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M6.847 6.501c-.439 0-.722 0-.77.002a2.73 2.73 0 0 0-1.91.88 2.5 2.5 0 0 0-.664 1.805c0 2.893.001 7.235.008 7.473.114 1.385 1.412 2.444 2.898 2.33 2.75.004 6.824.013 7.015.006a2.73 2.73 0 0 0 1.908-.877 2.49 2.49 0 0 0 .666-1.8v-.033c0-2.86 0-7.199-.007-7.432-.113-1.392-1.417-2.464-2.91-2.347-2.067-.003-4.886-.007-6.234-.007m-.7 14c-2.115.001-3.959-1.619-4.133-3.744-.003-.04-.013-.166-.01-7.535A3.95 3.95 0 0 1 3.06 6.37a4.22 4.22 0 0 1 2.96-1.366c.14-.01 4.947.002 7.006.006 2.25-.158 4.281 1.512 4.462 3.753.008.11.011 2.634.01 7.51a3.98 3.98 0 0 1-1.06 2.862 4.22 4.22 0 0 1-2.962 1.361c-.14.01-4.95-.003-7.012-.007a4 4 0 0 1-.318.012"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20.819 18.35c-.451 0-.896-.156-1.264-.457l-3.28-2.696a.75.75 0 0 1 .952-1.159l3.28 2.696c.22.18.442.106.528.064a.48.48 0 0 0 .285-.449L21.333 9a.48.48 0 0 0-.285-.45.49.49 0 0 0-.529.065l-3.293 2.695a.75.75 0 0 1-1.055-.106.75.75 0 0 1 .105-1.054l3.294-2.696a1.98 1.98 0 0 1 2.122-.259 1.98 1.98 0 0 1 1.142 1.807l-.012 7.35a1.98 1.98 0 0 1-1.145 1.803 2 2 0 0 1-.857.194"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_35_7857)">
        <path fill={color} d="M.4-.061h24v24H.4z"></path>
      </g>
    </svg>
  );
};

export default CameraIcon;
