type SmallSwitchCardProps = {
  title: string;
  icon: React.ReactNode;
  active?: boolean;
  onToggle?: () => void;
  children?: React.ReactNode;
};

const SmallSwitchCard: React.FC<SmallSwitchCardProps> = (props) => {
  const { title, icon, active = false, onToggle, children } = props;

  return (
    <div className="flex w-[160px] flex-col items-stretch gap-2 rounded-2xl bg-white p-2 text-[#131F44] shadow-[0px_2px_14px_rgba(0,0,0,0.06)]">
      {icon}
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between gap-3">
          <p className="m-0 text-[14px] font-bold">{title}</p>
          {children}
        </div>
        <div className="flex flex-row items-center justify-between gap-3">
          <span className="text-[12px]">{active ? "On" : "Off"}</span>
          <div
            className="h-4 w-8 cursor-pointer rounded-full bg-red-300 p-0.5"
            style={{
              transition: "background-color .3s",
              backgroundColor: active ? "#5AC8FA" : "rgba(0, 0, 0, .06)",
            }}
            onClick={() => {
              onToggle?.();
            }}
          >
            <div
              className="h-4 w-4 rounded-full bg-white shadow-sm"
              style={{
                transition: "transform .3s",
                transform: `translateX(${active ? "100%" : "0"})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallSwitchCard;
