import { AngeleyeIconProps } from "./AngeleyeIconProps";

const WidgetIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4660_2789"
        width="22"
        height="22"
        x="1"
        y="1"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill={color} d="M1 1h22v22H1z"></path>
      </mask>
      <g mask="url(#mask0_4660_2789)">
        <path
          fill={color}
          d="m15.524 11.86-3.367-3.366a.76.76 0 0 1-.235-.581.76.76 0 0 1 .234-.579l3.368-3.367a.76.76 0 0 1 .273-.182.9.9 0 0 1 .308-.053q.165 0 .307.053a.8.8 0 0 1 .272.182l3.367 3.367a.76.76 0 0 1 .234.581q0 .165-.053.307a.8.8 0 0 1-.181.272l-3.367 3.367a.8.8 0 0 1-.273.181.9.9 0 0 1-.308.053.76.76 0 0 1-.579-.235M4.49 10.08v-4.76q0-.353.239-.59a.8.8 0 0 1 .59-.239h4.76q.351 0 .59.239a.8.8 0 0 1 .238.59v4.76q0 .351-.238.59a.8.8 0 0 1-.59.238h-4.76a.8.8 0 0 1-.59-.238.8.8 0 0 1-.239-.59m8.603 8.602v-4.76q0-.351.238-.59a.8.8 0 0 1 .59-.238h4.76q.353 0 .59.238a.8.8 0 0 1 .238.59v4.76q0 .353-.238.59a.8.8 0 0 1-.59.239h-4.76a.8.8 0 0 1-.59-.239.8.8 0 0 1-.238-.59m-8.603 0v-4.76q0-.351.239-.59a.8.8 0 0 1 .59-.238h4.76q.351 0 .59.238a.8.8 0 0 1 .238.59v4.76q0 .353-.238.59a.8.8 0 0 1-.59.239h-4.76a.8.8 0 0 1-.59-.239.8.8 0 0 1-.239-.59m1.375-9.149h3.667V5.865H5.865zm10.262.994 2.59-2.59-2.59-2.589-2.59 2.59zm-1.66 7.609h3.668v-3.667h-3.667zm-8.602 0h3.667v-3.667H5.865z"
        ></path>
      </g>
    </svg>
  );
};

export default WidgetIcon;
