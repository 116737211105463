import { AngeleyeIconProps } from "./AngeleyeIconProps";

const FallIcon = (props: Omit<AngeleyeIconProps, "color">) => {
  const { size = 24 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4660_2816"
        width="20"
        height="16"
        x="2"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#000"
          d="M15.3 4.917a.56.56 0 0 0-.523.401c-.482 1.579-.63 2.132-.892 2.402-.167.174-.617.39-1.256.68L10.2 9.613a.99.99 0 0 1-.763.017c-.556-.225-.925-.388-1.155-.563-.42-.321-.699-.853-1.474-2.41a.556.556 0 1 0-.995.495c.775 1.556 1.112 2.276 1.795 2.798.592.451 1.313.678 2.608 1.201l.138.277 1.221 2.449.002.002c.515 1.03 2.033 1.498 2.936 1.047l2.277-1.12 1.623 2.84c.525.918 1.903.13 1.377-.789l-1.895-3.31a.793.793 0 0 0-.995-.339l-1.457.609 1.466-1.306 3.016.853c1.03.309 1.47-1.25.432-1.527l-3.435-.972a.8.8 0 0 0-.744.171l-1.893 1.687-.9-1.806-.253-.507c.713-.31 1.181-.532 1.553-.917.56-.58.68-1.296 1.154-2.85a.556.556 0 0 0-.54-.725zm-4.857 1.288a1.414 1.414 0 1 0 0 2.83c.786 0 1.422-.63 1.422-1.415a1.42 1.42 0 0 0-1.422-1.415M3.158 18.116a1.68 1.68 0 0 0 1.145-.448.95.95 0 0 1 .643-.251c.241 0 .462.09.643.25.322.288.723.449 1.145.449a1.68 1.68 0 0 0 1.146-.448.95.95 0 0 1 .643-.251c.24 0 .462.09.643.25.321.288.723.449 1.145.449a1.68 1.68 0 0 0 1.145-.448.95.95 0 0 1 .643-.251c.241 0 .462.09.643.25.322.288.724.449 1.146.449a1.68 1.68 0 0 0 1.145-.448.95.95 0 0 1 .643-.251c.24 0 .462.09.643.25.321.288.723.449 1.145.449a1.68 1.68 0 0 0 1.146-.448.95.95 0 0 1 .643-.251c.24 0 .482.09.642.25.322.288.724.449 1.146.449.301 0 .542.215.542.484 0 .268-.24.484-.542.484a2.86 2.86 0 0 1-1.808-.645 2.86 2.86 0 0 1-1.809.645 2.95 2.95 0 0 1-1.808-.628 2.85 2.85 0 0 1-1.808.628A2.95 2.95 0 0 1 12 18.456a2.85 2.85 0 0 1-1.808.628 2.95 2.95 0 0 1-1.808-.628 2.85 2.85 0 0 1-1.809.628 2.95 2.95 0 0 1-1.808-.628 2.85 2.85 0 0 1-1.808.628c-.301 0-.542-.215-.542-.484.199-.269.44-.484.74-.484"
        ></path>
      </mask>
      <g mask="url(#mask0_4660_2816)">
        <path fill="#000" fillOpacity="0.38" d="M1.5 1.5h24v24h-24z"></path>
      </g>
      <rect width="10" height="10" x="1" y="12" fill="#fff" rx="5"></rect>
      <mask
        id="mask1_4660_2816"
        width="10"
        height="10"
        x="1"
        y="12"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M6 21.166a4.166 4.166 0 1 1 0-8.333A4.166 4.166 0 0 1 10.167 17 4.163 4.163 0 0 1 6 21.166m1.33-2.62q.074.044.162.045a.314.314 0 0 0 .158-.583l-1.483-.883V15.2a.312.312 0 1 0-.625 0v2.104c0 .108.058.208.154.267z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1_4660_2816)">
        <path fill="#1F5B95" d="M1 11.975h10v10H1z"></path>
      </g>
    </svg>
  );
};

export default FallIcon;
