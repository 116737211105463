import { StaticImage } from "gatsby-plugin-image";
import { l_angeleye } from "../locales";

type MonitoringSectionProps = {
  children?: React.ReactNode;
};

const MonitoringSection: React.FC<MonitoringSectionProps> = (props) => {
  return (
    <div className="bg-[#C1CFE6] py-24">
      <div className="mx-auto box-border flex w-full max-w-screen-lg flex-col-reverse items-stretch justify-center gap-8 px-4 md:flex-row md:items-center md:gap-16 md:px-8">
        <div className="flex max-w-full flex-col gap-4 md:max-w-[360px]">
          <h2>{l_angeleye("monitoring.title")}</h2>
          <p>{l_angeleye("monitoring.description")}</p>
        </div>
        <StaticImage
          src="../assets/images/monitoring.png"
          alt="Angeleye Logo"
          quality={100}
          objectFit="contain"
        />
      </div>
    </div>
  );
};

export default MonitoringSection;
