import { AngeleyeIconProps } from "./AngeleyeIconProps";

const PeopleErrorIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#FF5349" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_35_7831"
        width="22"
        height="17"
        x="1"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M17.887 12.146a.75.75 0 0 1-.103-1.493 2.13 2.13 0 0 0 1.827-2.1 2.115 2.115 0 0 0-1.78-2.094.75.75 0 0 1 .245-1.48 3.61 3.61 0 0 1 3.035 3.576 3.64 3.64 0 0 1-3.12 3.584 1 1 0 0 1-.104.007"
          clipRule="evenodd"
        ></path>
        <mask
          id="mask1_35_7831"
          width="5"
          height="5"
          x="18"
          y="14"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "luminance" }}
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M18.978 14.002h3.794v4.53h-3.794z"
            clipRule="evenodd"
          ></path>
        </mask>
        <g mask="url(#mask1_35_7831)">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M20.861 18.532a.75.75 0 0 1-.267-1.451c.678-.258.678-.543.678-.68 0-.438-.557-.745-1.655-.909a.75.75 0 0 1-.63-.854.755.755 0 0 1 .853-.63c2.423.364 2.932 1.502 2.932 2.393 0 .544-.214 1.537-1.644 2.082a.8.8 0 0 1-.267.049"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.887 15.914c-2.376 0-5.21.292-5.21 1.682 0 1.401 2.834 1.695 5.21 1.695s5.208-.29 5.208-1.678c0-1.404-2.832-1.699-5.208-1.699m0 4.877c-1.66 0-6.71 0-6.71-3.195 0-3.182 5.05-3.182 6.71-3.182 1.659 0 6.708 0 6.708 3.2 0 3.177-4.873 3.177-6.708 3.177M11.887 5.5a3.07 3.07 0 0 0-3.068 3.069c-.003.82.31 1.587.886 2.166.575.579 1.34.9 2.157.903l.025.75v-.75a3.07 3.07 0 0 0 3.069-3.069 3.073 3.073 0 0 0-3.07-3.069m0 7.638h-.027a4.52 4.52 0 0 1-3.22-1.346A4.52 4.52 0 0 1 7.32 8.566 4.573 4.573 0 0 1 11.887 4c2.52 0 4.569 2.05 4.569 4.569a4.574 4.574 0 0 1-4.57 4.569M5.886 12.147a1 1 0 0 1-.104-.008 3.64 3.64 0 0 1-3.12-3.582A3.61 3.61 0 0 1 5.699 4.98a.75.75 0 0 1 .244 1.48 2.116 2.116 0 0 0-1.78 2.096 2.13 2.13 0 0 0 1.827 2.098.75.75 0 0 1-.103 1.493M2.912 18.531a.8.8 0 0 1-.267-.049C1.215 17.936 1 16.943 1 16.4c0-.89.51-2.029 2.933-2.392a.755.755 0 0 1 .853.63.75.75 0 0 1-.63.854c-1.099.164-1.656.47-1.656.908 0 .137 0 .421.68.68a.75.75 0 0 1-.268 1.451"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_35_7831)">
        <path fill={color} d="M0-.061h24v24H0z"></path>
      </g>
      <rect width="10" height="10" x="14" y="0.5" fill="#fff" rx="5"></rect>
      <mask
        id="mask2_35_7831"
        width="10"
        height="9"
        x="14"
        y="1"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M14.833 5.5a4.166 4.166 0 1 1 8.333 0c0 2.3-1.862 4.166-4.166 4.166A4.17 4.17 0 0 1 14.833 5.5m3.8-1.58c0-.2.167-.366.367-.366s.363.167.363.367v1.841c0 .2-.163.363-.363.363a.366.366 0 0 1-.367-.363zm.371 3.53a.367.367 0 0 1-.367-.367c0-.2.163-.362.363-.362.204 0 .367.162.367.362s-.163.367-.363.367"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2_35_7831)">
        <path fill={color} d="M14 .475h10v10H14z"></path>
      </g>
    </svg>
  );
};

export default PeopleErrorIcon;
