import { StaticImage } from "gatsby-plugin-image";
import { l_angeleye } from "../../locales";
import PeopleIcon from "../icons/PeopleIcon";

const ChartCard: React.FC = () => {
  return (
    <div className="flex w-[320px] flex-col items-stretch gap-2 rounded-2xl bg-white p-4 text-[#131F44] shadow-[0px_2px_14px_rgba(0,0,0,0.06)]">
      <p className="m-0 text-[12px] font-bold uppercase text-[#004587]/75">
        {l_angeleye("widgets.widgetsStrings.safeguard")}
      </p>
      <StaticImage
        src="../../assets/images/chart.svg"
        alt="Chart"
        width={320}
        height={68}
      />
      <p className="m-0 mt-2 text-[12px]">
        {l_angeleye("widgets.widgetsStrings.totalCapacity")}
      </p>
      <div className="flex flex-row items-center gap-6">
        <div className="flex flex-row items-center gap-4">
          <PeopleIcon />
          <span className="text-[12px]">
            <span className="text-[18px] font-bold">115</span>/348
          </span>
        </div>
        <div className="flex flex-1 flex-row items-center gap-4">
          <div className="h-3 w-full flex-1 rounded-full bg-[rgba(0,0,0,0.06)]">
            <div className="h-3 w-[48%] rounded-full bg-[#5AC8FA]"></div>
          </div>
          <span className="text-[12px]">48%</span>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
