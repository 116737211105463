import { AngeleyeIconProps } from "./AngeleyeIconProps";

const WatchIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#5AC8FA" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4654_2320"
        width="16"
        height="23"
        x="4"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#000"
          d="M19.273 11.206V7.54c0-1.362-.93-2.5-2.182-2.829V1.673a.73.73 0 0 0-.727-.734H7.636a.73.73 0 0 0-.727.734V4.71a2.925 2.925 0 0 0-2.182 2.83v1.466A.73.73 0 0 0 4 9.74a.73.73 0 0 0 .727.734v2.933A.73.73 0 0 0 4 14.14a.73.73 0 0 0 .727.734v1.467c0 1.362.93 2.5 2.182 2.829v3.037a.73.73 0 0 0 .727.733h8.728a.73.73 0 0 0 .727-.733V19.17a2.925 2.925 0 0 0 2.182-2.83v-3.666A.73.73 0 0 0 20 11.94a.73.73 0 0 0-.727-.734m-3.637-8.8v2.2H8.364v-2.2zM8.364 21.473v-2.2h7.272v2.2zm9.454-5.133c0 .808-.653 1.466-1.454 1.466H7.636a1.46 1.46 0 0 1-1.454-1.466v-8.8c0-.81.653-1.467 1.454-1.467h8.728c.801 0 1.454.657 1.454 1.466zm-1.704-6.716a.737.737 0 0 1 0 1.037.72.72 0 0 1-1.028 0A4.32 4.32 0 0 0 12 9.373a4.32 4.32 0 0 0-3.086 1.288.723.723 0 0 1-1.028 0 .737.737 0 0 1 0-1.037A5.76 5.76 0 0 1 12 7.906c1.554 0 3.015.61 4.114 1.718M14.056 11.7a.737.737 0 0 1 0 1.036.72.72 0 0 1-1.028 0c-.552-.555-1.505-.555-2.056 0a.723.723 0 0 1-1.028 0 .737.737 0 0 1 0-1.037c1.1-1.109 3.013-1.109 4.112 0m-1.329 2.806a.73.73 0 0 1-.727.733.73.73 0 0 1-.727-.733.73.73 0 0 1 .727-.733.73.73 0 0 1 .727.733"
        ></path>
      </mask>
      <g mask="url(#mask0_4654_2320)">
        <path fill={color} d="M0-.061h24v24H0z"></path>
      </g>
    </svg>
  );
};

export default WatchIcon;
