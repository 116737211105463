import { AngeleyeIconProps } from "./AngeleyeIconProps";

const ErrorIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#FF5349" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4660_2808"
        width="21"
        height="21"
        x="2"
        y="1"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M2 11.999c0-5.519 4.48-10 10-10 5.53 0 10 4.481 10 10 0 5.521-4.47 10-10 10-5.52 0-10-4.479-10-10m9.12-3.79c0-.479.4-.88.88-.88s.87.401.87.88v4.42a.87.87 0 0 1-.87.87.88.88 0 0 1-.88-.87zm.89 8.471c-.49 0-.88-.4-.88-.88s.39-.87.87-.87c.49 0 .88.39.88.87s-.39.88-.87.88"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_4660_2808)">
        <path fill={color} d="M0-.061h24v24H0z"></path>
      </g>
    </svg>
  );
};

export default ErrorIcon;
