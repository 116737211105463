import { StaticImage } from "gatsby-plugin-image";
import { useEffect, useState } from "react";
import { animated, useInView, useSpring } from "react-spring";
import { l_angeleye } from "../locales";

type WatchSectionProps = {
  children?: React.ReactNode;
};

const WatchSection: React.FC<WatchSectionProps> = (props) => {
  const [isActive, setIsActive] = useState(false);

  const [ref, isInView] = useInView({
    amount: "any",
    rootMargin: "300px 0px",
  });

  const [container] = useSpring(
    () => ({
      from: { scale: 0.8, y: "5%" },
      to: async (next, cancel) => {
        if (isActive) {
          await next({ scale: 0.81, y: "5%" });
          await next({ scale: 0.82, y: "5%" });
          await next({ scale: 1, y: "-5%" });
        }
      },
      delay: 1000,
    }),
    [isActive],
  );

  const [mainWatch] = useSpring(
    () => ({
      from: { y: "20%", x: 0, opacity: 0 },
      to: async (next, cancel) => {
        if (isActive) {
          await next({ y: "0%", x: 0, opacity: 100 });
          await next({ y: "0%", x: 0 });
          await next({ y: "0%", x: 0 });
        }
      },
      delay: 1000,
    }),
    [isActive],
  );

  const [leftWatch] = useSpring(
    () => ({
      from: { y: "-25%", x: "-50%", opacity: 0 },
      to: async (next, cancel) => {
        if (isActive) {
          await next({ y: "-50%", x: "-50%", opacity: 100 });
          await next({ y: "-50%", x: "-90%" });
          await next({ y: "-50%", x: "-90%" });
        }
      },
      delay: 1000,
    }),
    [isActive],
  );

  const [rightWatch] = useSpring(
    () => ({
      from: { y: "-25%", x: "-50%", opacity: 0 },
      to: async (next, cancel) => {
        if (isActive) {
          await next({ y: "-50%", x: "-50%", opacity: 100 });
          await next({ y: "-50%", x: "-10%" });
          await next({ y: "-50%", x: "-10%" });
        }
      },
      delay: 1000,
    }),
    [isActive],
  );

  useEffect(() => {
    if (isInView) {
      setIsActive(true);
    }
  }, [isInView]);

  return (
    <div className="flex flex-col gap-2 bg-[#0E1623] py-8 md:gap-20 md:py-40 md:pb-12">
      <div className="mx-auto box-border flex w-full max-w-screen-lg flex-col-reverse items-stretch justify-center gap-8 px-4 md:flex-row md:items-center md:gap-16 md:px-8">
        <animated.div
          className="min-height-[80px] relative"
          id="container"
          style={container}
          ref={ref}
        >
          <animated.div
            className="relative z-10 mx-auto text-center"
            style={mainWatch}
          >
            <StaticImage
              src="../assets/images/watch_main.png"
              alt="Watch main"
            />
          </animated.div>
          <animated.div
            id="watch_left"
            className="absolute left-[50%] top-[50%] h-[368px] w-[252px] translate-x-[-50%] translate-y-[-50%] sm:h-[452px] sm:w-[310px]"
            style={leftWatch}
          >
            <StaticImage
              src="../assets/images/watch_left.png"
              alt="Watch left"
            />
          </animated.div>
          <animated.div
            id="watch_right"
            className="absolute left-[50%] top-[50%] h-[368px] w-[252px] translate-x-[-50%] translate-y-[-50%] sm:h-[452px] sm:w-[310px]"
            style={rightWatch}
          >
            <StaticImage
              src="../assets/images/watch_right.png"
              alt="Watch right"
            />
          </animated.div>
        </animated.div>
      </div>
      <div className="mx-auto box-border flex w-full max-w-screen-lg flex-col items-stretch justify-between gap-8 px-4 md:flex-row md:items-start md:gap-16 md:px-8">
        <p className="my-0 text-[26px] font-bold text-[#C1CFE6] md:max-w-[377px]">
          {l_angeleye("watch.title")}
        </p>
        <p className="my-0 text-[#C1CFE6] md:max-w-[334px]">
          <b>{l_angeleye("watch.subtitle_1")}</b>
          <br />
          {l_angeleye("watch.text_1")}
          <br />
          <br />
          <b>{l_angeleye("watch.subtitle_2")}</b>
          <br />
          {l_angeleye("watch.text_2")}
        </p>
      </div>
    </div>
  );
};

export default WatchSection;
