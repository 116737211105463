import { AngeleyeIconProps } from "./AngeleyeIconProps";

const CameraFilledIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
    >
      <mask
        id="mask0_55_10050"
        width="25"
        height="25"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path fill="#D9D9D9" d="M.639.082h24v24h-24z"></path>
      </mask>
      <g mask="url(#mask0_55_10050)">
        <path
          fill={color}
          d="M5.188 19.582q-.758 0-1.283-.525a1.75 1.75 0 0 1-.525-1.283V6.39q0-.758.525-1.283t1.283-.525h11.385q.757 0 1.282.525t.525 1.283v4.577l2.746-2.746q.222-.222.497-.111.276.11.276.422v7.1q0 .312-.276.422t-.497-.11l-2.746-2.747v4.577q0 .758-.525 1.283t-1.282.525z"
        ></path>
      </g>
    </svg>
  );
};

export default CameraFilledIcon;
