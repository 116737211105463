import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";

import Angeleye from "../../features/case_study/angeleye";
import { l_angeleye } from "../../features/case_study/angeleye/locales";

export default Angeleye;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_angeleye("seo.title")}
      description={l_angeleye("seo.description")}
      image="/case_studies_png/cover-angeleye.png"
      headProps={headProps}
    />
  );
};
