import { AngeleyeIconProps } from "./AngeleyeIconProps";

const ChangeIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_35_8031"
        width="14"
        height="17"
        x="5"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#000"
          d="M5 16.29c0 .394.32.714.714.714h10.151l-2.767 2.768a.714.714 0 1 0 1.01 1.01l3.986-3.987a.714.714 0 0 0-.504-1.22H5.714A.714.714 0 0 0 5 16.29m12.59-6.882H5.714A.714.714 0 0 1 5.21 8.19l3.987-3.987a.714.714 0 0 1 1.01 1.01L7.438 7.98H17.59a.714.714 0 1 1 0 1.428"
        ></path>
      </mask>
      <g mask="url(#mask0_35_8031)">
        <path fill={color} d="M-.218 0h24v24h-24z"></path>
      </g>
    </svg>
  );
};

export default ChangeIcon;
