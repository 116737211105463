import { AngeleyeIconProps } from "./AngeleyeIconProps";

const TabletIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#5AC8FA" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_4654_2478"
        width="14"
        height="23"
        x="5"
        y="0"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#000"
          d="M16.2 22.94H7.8c-1.544 0-2.8-1.316-2.8-2.934V3.873C5 2.255 6.256.939 7.8.939h8.4c1.544 0 2.8 1.316 2.8 2.934v16.133c0 1.618-1.256 2.933-2.8 2.933M7.8 2.406c-.772 0-1.4.658-1.4 1.467v16.133c0 .81.628 1.467 1.4 1.467h8.4c.772 0 1.4-.658 1.4-1.467V3.873c0-.81-.628-1.467-1.4-1.467zm8.16 8.255a.76.76 0 0 0 0-1.037C14.902 8.517 13.496 7.906 12 7.906s-2.902.61-3.96 1.718a.76.76 0 0 0 0 1.037.68.68 0 0 0 .99 0A4.08 4.08 0 0 1 12 9.373c1.122 0 2.177.458 2.97 1.288a.68.68 0 0 0 .495.215.68.68 0 0 0 .495-.215m-1.98 2.076a.76.76 0 0 0 0-1.037c-1.06-1.11-2.9-1.11-3.959 0a.76.76 0 0 0 0 1.037.677.677 0 0 0 .99 0 1.383 1.383 0 0 1 1.978 0 .68.68 0 0 0 .495.214.68.68 0 0 0 .495-.214M12 13.773c-.387 0-.7.328-.7.733s.313.734.7.734.7-.329.7-.734-.313-.733-.7-.733m2.1-9.9c0-.405-.313-.733-.7-.733h-2.8c-.387 0-.7.328-.7.733s.313.733.7.733h2.8c.387 0 .7-.328.7-.733m-.7 16.133c0-.405-.313-.733-.7-.733h-1.4c-.387 0-.7.328-.7.733s.313.734.7.734h1.4c.387 0 .7-.329.7-.734"
        ></path>
      </mask>
      <g mask="url(#mask0_4654_2478)">
        <path fill={color} d="M0-.061h24v24H0z"></path>
      </g>
    </svg>
  );
};

export default TabletIcon;
