import { AngeleyeIconProps } from "./AngeleyeIconProps";

const WarningIcon = (props: AngeleyeIconProps) => {
  const { size = 24, color = "#131F44" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_35_8029"
        width="22"
        height="19"
        x="1"
        y="3"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M12.013 4.5c-.476 0-.9.246-1.138.659L3.677 17.724a1.3 1.3 0 0 0 .005 1.308c.237.409.66.654 1.134.654h14.383c.472 0 .896-.245 1.133-.654.238-.409.24-.898.004-1.308L13.15 5.159a1.3 1.3 0 0 0-1.138-.659m7.186 16.686H4.816a2.78 2.78 0 0 1-2.432-1.402 2.78 2.78 0 0 1-.01-2.805l7.2-12.566A2.78 2.78 0 0 1 12.014 3a2.78 2.78 0 0 1 2.44 1.415l7.186 12.564c.503.879.5 1.928-.01 2.805a2.78 2.78 0 0 1-2.43 1.402m-7.195-7.022a.75.75 0 0 1-.75-.75v-3.1a.75.75 0 0 1 1.5 0v3.1a.75.75 0 0 1-.75.75M11 16.499a1.003 1.003 0 0 0 2.005 0 1 1 0 0 0-1-1h-.01a.995.995 0 0 0-.995 1"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_35_8029)">
        <path fill={color} d="M0-.061h24v24H0z"></path>
      </g>
    </svg>
  );
};

export default WarningIcon;
