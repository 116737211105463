import { l_angeleye } from "../../locales";
import CameraIcon from "../icons/CameraIcon";
import ErrorIcon from "../icons/ErrorIcon";

const YellowCard: React.FC = () => {
  return (
    <div className="flex w-[160px] flex-col items-stretch gap-2 rounded-2xl bg-[#FFDF61] p-2 text-white">
      <div className="flex flex-row items-center gap-5">
        <CameraIcon color="#ffffff" />
        <div className="flex flex-1 flex-row items-center gap-1">
          <ErrorIcon color="#ffffff" size={18} />
          <span className="text-[12px]">2</span>
        </div>
      </div>
      <p className="m-0 text-[14px] font-bold">
        {l_angeleye("widgets.widgetsStrings.cameras")}
        <br />
        {l_angeleye("widgets.widgetsStrings.active_fm")}
      </p>
      <span className="text-[12px]">
        <span className="text-[24px] font-bold">4</span>/5
      </span>
    </div>
  );
};

export default YellowCard;
